import PropTypes from 'prop-types';
import React, { useState } from 'react';

const Reservation = (props) => {
  const [name, setName] = useState(props.name);
  const [day, setDay] = useState(props.day);
  const [calendar, setCalendar] = useState(props.calendar);

  return (
  <div>
    <div class="row">
      <div class="col-md-12">
        <section class="ftco-section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="content w-100">
                  <div class="calendar-container">
                    <div class="calendar">
                      <strong><h2 class="heading-section">Select a day</h2></strong>
                      <div class="year-header">
                        <span class="left-button fa fa-chevron-left" id="prev"> </span>
                        <span class="year" id="label"></span>
                        <span class="right-button fa fa-chevron-right" id="next"> </span>
                      </div>
                      <table class="months-table w-100">
                        <tbody>
                          <tr class="months-row">
                            <td class="month">Jan</td>
                            <td class="month">Feb</td>
                            <td class="month">Mar</td>
                            <td class="month">Apr</td>
                            <td class="month">May</td>
                            <td class="month">Jun</td>
                            <td class="month">Jul</td>
                            <td class="month">Aug</td>
                            <td class="month">Sep</td>
                            <td class="month">Oct</td>
                            <td class="month">Nov</td>
                            <td class="month">Dec</td>
                          </tr>
                        </tbody>
                      </table>

                      <table class="days-table w-100">
                        <td class="day">Sun</td>
                        <td class="day">Mon</td>
                        <td class="day">Tue</td>
                        <td class="day">Wed</td>
                        <td class="day">Thu</td>
                        <td class="day">Fri</td>
                        <td class="day">Sat</td>
                      </table>
                      <div class="frame">
                        <table class="dates-table w-100">
                          <tbody class="tbody">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="events-container">
                  </div>
                  <div class="dialog" id="dialog">
                      <form class="form" id="form">
                      <button class="button" id="add-button">Enviar dades al controlador</button>
                        <div class="row">
                          <div class="col-6">
                            <h2 class="dialog-header text-left"> Pick a Time </h2>

                            <div class="form-container picktime" align="center">

                            </div>

                            <input type="hidden" name="month" id="monthhidden" value= "" />
                            <input type="hidden" name="year" id="yearhidden" value= "" />
                          </div>
                          <div class="col-6 comments">
                            <p> Do you want to add some content? </p>
                            <div class="form-container" align="center">

                              <input type="button" value="Send the date for the meeting" class="button button-white" id="ok-button" />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>


    <h3>{JSON.stringify(calendar[3]["month_name"])}</h3>
    <h3>{JSON.stringify(calendar[3]["month"]["day"])}</h3>
    <hr /><br />
    <h3>{JSON.stringify(calendar)}</h3>


    <div class="row">
      <p>REACT EXAMPLE </p>

      <hr />

      {name}

      <form action="" method="get" class="calendar">
          <label htmlFor="name">Tu nombre: </label>
          <input id="name" type="text" class="form-control" placeholder="Hola" value={name} onChange={(e) => setName(e.target.value)} />
      </form>
    </div>
  </div>
  );
};



export default Reservation;
